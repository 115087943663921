<template>
  <div>
    <div class="candidate-profile-basic-details-wrapper">
      <div style="position: fixed; width: 95%; background: #fff9e7; z-index: 9">
        <div class="candidate-profile-basic-details">
          <div class="candidate-profile-basic-details-section-1">
            <div>
              <a-rate
                :value="parseFloat(data.rating / 2)"
                disabled
                allowHalf
              />
            </div>
            <div>
              <!-- <template>
              <img v-if="text" src="{{data.}}" alt="Candidate image" />
              <img v-else src="../../../assets/candidate.png" alt="..." />
            </template> -->
              <a-avatar shape="square" :size="100">
                <template #icon><UserOutlined /></template>
              </a-avatar>
            </div>
            <div class="gender-and-year">
              <span v-if="data.gender">{{ data.gender }},  {{ data.age ? data.age : '-' }}</span>
              <span></span>
            </div>
          </div>
          <div class="candidate-profile-basic-details-section-2">
            <div>
              <span class="candidate-profile-basic-details-name">{{
                data.name
              }}</span>
              <span
                class="candidate-profile-basic-details-2-status-tag"
                :style="[
                  data.status == 'Active'
                    ? { background: 'rgba(111, 207, 151, 0.4)' }
                    : { background: 'red'},
                ]"
              >
                {{ data.status }}
              </span>
              <span class="candidate-profile-resume-details-header"
                >RES ID :</span
              >
              <span
                class="candidate-profile-resume-details-value spacer-right"
                >{{ data.resume.resumeId }}</span
              >
              <span class="candidate-profile-resume-details-header"
                >Resume Type :</span
              >
              <span class="candidate-profile-resume-details-value">{{
                data.resume.status ? data.resume.status : '-'
              }}</span>
            </div>
            <div v-for="experience in data.experiences" :key="experience">
              <p class="candidate-profile-professional-detail">
                {{ experience.designation }}
              </p>
              <p class="candidate-profile-professional-detail">
                {{ experience.company }}
              </p>
            </div>
            <div>
              <p class="candidate-profile-professional-detail">
                {{ data.email }}, -<!-- {{data.contacts && data.contacts[0] ? data.contacts[0].phone : ''}} -->
              </p>
            </div>

            <div
              style="
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: flex-start;
              "
            >
              <div style="width: 20%">
                <span class="candidate-profile-professional-details-2-header"
                  >Level</span
                >
                <span class="candidate-profile-professional-details-2-value">{{
                  data.levels && data.levels.length > 0 ? data.levels : "-"
                }}</span>
              </div>
              <div style="width: 20%">
                <span class="candidate-profile-professional-details-2-header"
                  >Current Experience</span
                >
                <span class="candidate-profile-professional-details-2-value"
                  >-</span
                >
              </div>
              <div style="width: 30%">
                <span class="candidate-profile-professional-details-2-header">
                  Compensation
                </span>
                <span class="candidate-profile-professional-details-2-value">
                  {{ data.ctc }}
                </span>
              </div>
              <div style="width: 20%">
                <span class="candidate-profile-professional-details-2-header">
                  Tot. Work Exp.
                </span>
                <span
                  v-if="data.totalWorkExp"
                  class="candidate-profile-professional-details-2-value"
                >
                  {{ data.totalWorkExp }}
                </span>
                <span
                  v-else
                  class="candidate-profile-professional-details-2-value"
                >
                  0
                </span>
              </div>
            </div>
          </div>
          <div class="candidate-profile-basic-details-section-3">
            <a
              href="#"
              @click="attachVacancy('candidate')"
              class="candidate-profile-vacancy-link"
              >Attach to Vacancy</a
            >
            <a-modal
              v-model:visible="visible"
              class="modal-custom"
              title=""
              :footer="null"
              @ok="hideModal"
              destroyOnClose
            >
              <div>
                <AttachVacancy
                  :formName="modalform"
                  @attachCandidateToVacancyInProfile="
                    attachCandidateToVacancyInProfile
                  "
                  @onAddEducation="onAddEducation"
                  :EducData="EducData"
                  @getEditEducationFormData="getEditEducationFormData"
                  @onAddWorkDetails="onAddWorkDetails"
                  :workData="workData"
                  @getEditWorkDetailsFormData="getEditWorkDetailsFormData"
                  @onCancel="onCancel"
                />
              </div>
              <template #footer>
                <a-button
                  type="link"
                  style="
                    color: rgb(76, 81, 85);
                    font-size: $font_size_12;
                    font-family: Poppins;
                    font-weight: 500;
                  "
                  key="back"
                  >Cancel</a-button
                >
                <a-button
                  key="submit"
                  style="
                    background: RGB(228, 129, 106);
                    color: #ffffff;
                    border-radius: 15px;
                    font-size: $font_size_12;
                    font-family: Poppins;
                    font-weight: 500;
                  "
                  @click="attachCandidateToVacancyInProfile"
                  >Save</a-button
                >
              </template>
            </a-modal>
            <div class="icon-list">
              <TwitterSquareFilled
                class="candidate-profile-social-media-icon"
                v-if="Object.keys(data.socialAccounts.twitter).length !== 0"
              />
              <LinkedinFilled
                class="candidate-profile-social-media-icon"
                v-if="Object.keys(data.socialAccounts.linkedIn).length !== 0"
              />
              <FacebookFilled
                class="candidate-profile-social-media-icon"
                v-if="Object.keys(data.socialAccounts.facebook).length !== 0"
              />
            </div>
          </div>
          <div class="candidate-profile-basic-details-section-4-parent">
            <div class="candidate-profile-basic-details-section-4">
              <a-card class="candidate-profile-basic-details-section-4-card-1">
                <span
                  class="candidate-profile-basic-details-section-4-card-number"
                  >{{ widgetsData.jobOffered }}</span
                >
                <span
                  class="candidate-profile-basic-details-section-4-card-text"
                  >Job Offers</span
                >
              </a-card>

              <a-card class="candidate-profile-basic-details-section-4-card-2">
                <span
                  class="candidate-profile-basic-details-section-4-card-number"
                  >{{ widgetsData.interviews ? widgetsData.interviews : '0' }}</span
                >
                <span
                  class="candidate-profile-basic-details-section-4-card-text"
                  >Interviews</span
                >
              </a-card>

              <a-card class="candidate-profile-basic-details-section-4-card-3">
                <span
                  class="candidate-profile-basic-details-section-4-card-number"
                  style="color: #eb5757"
                  >{{ widgetsData.rejected ? widgetsData.rejected : '0' }}</span
                >
                <span
                  class="candidate-profile-basic-details-section-4-card-text"
                  >Rejections</span
                >
              </a-card>
            </div>
            <span class="candidate-lead-cic">Lead CIC : </span
            ><span
              class="candidate-lead-cic-data"
              v-if="data.relationshipManager"
            >
              {{
                data.relationshipManager.length
                  ? data.relationshipManager[0].name
                  : "-"
              }}</span
            >
            <span v-else>-</span>
          </div>
          <div class="candidate-profile-basic-details-section-5">
            <a style="color: black" href="/candidates">
              <CloseOutlined
                class="candidate-profile-basic-details-section-5-action-icon"
              />
            </a>
            <DeleteOutlined
              class="candidate-profile-basic-details-section-5-action-icon"
            />
            <router-link :to="{path: '/edit-candidate/' + id}"><EditOutlined
              class="candidate-profile-basic-details-section-5-action-icon"
              style="color:#000000"
            /></router-link>
          </div>
        </div>
        <div class="candidate-profile-details-menu">
          <a-divider />
          <a-menu
            :default-selected-keys="current"
            :selected-keys="current"
            @click="handleMenuClick"
            mode="horizontal"
            class="candidate-details-menu"
          >
            <a-menu-item key="about">
              <a href="#"> About </a>
            </a-menu-item>
            <a-menu-item key="meeting-history"
              ><a href="#meeting-history">Meeting History</a></a-menu-item
            >
            <a-menu-item key="vacancy-history"
              ><a href="#vacancy-history">Vacancy History</a></a-menu-item
            >
            <a-menu-item key="education"
              ><a href="#education">Education</a></a-menu-item
            >
            <a-menu-item key="tags"><a href="#education">Tags</a></a-menu-item>
            <a-menu-item key="documents"
              ><a href="#documents">Documents</a></a-menu-item
            >
            <a-menu-item key="background"
              ><a href="#background">Background</a></a-menu-item
            >
            <a-menu-item key="aum"><a href="#aum">AUM</a></a-menu-item>
          </a-menu>
          <a-divider class="menu-bottom-divider" />
        </div>
      </div>
    </div>

    <div class="candidate-profile-about-section">
      <div class="candidate-work-details-section">
        <div class="header">
          <span class="title">Work Details</span>
          <PlusOutlined
            class="add-icon"
            @click="attachVacancy('workinfo', 'add')"
          />
        </div>
        <div class="main-section">
          <a-timeline
            class="timeline"
            v-for="(experience, index) in data.experiences"
            :key="index"
          >
            <a-timeline-item class="timeline-item" v-if="experience.current">
              <a-card>
                <div class="card-header">
                  <span>{{ experience.company }}</span>
                  <span class="right-header" v-if="experience.from">
                    {{ displayDate(experience.from) }} - Present
                    <a-popover @click="openPopover(index)">
                      <template #content>
                        <div style="width: 120px">
                          <div
                            @click="
                              onUpdateExperience(
                                experience.id,
                                index,
                                experience
                              )
                            "
                            style="font-weight: bold; cursor: pointer"
                          >
                            Edit
                          </div>
                          <hr />
                          <div
                            @click="onDeleteExperience(experience.id, index)"
                            style="font-weight: bold; cursor: pointer"
                          >
                            Delete
                          </div>
                        </div>
                      </template>
                      <MoreOutlined
                        style="
                          color: #000000;
                          font-size: 20px;
                          font-weight: bold;
                        "
                      />
                    </a-popover>
                  </span>
                </div>
                <p class="middle-section-first">
                  {{ experience.designation }}
                </p>
                <div class="card-bottom-section">
                  <div style="margin-right: 6rem">
                    <span class="work-detail-bottom-header"> Reports To </span>
                    <span class="work-detail-bottom-value">{{
                      experience.reportingTo
                    }}</span>
                  </div>
                  <div style="margin-right: 6rem">
                    <span class="work-detail-bottom-header"> Level </span>
                    <span class="work-detail-bottom-value">{{
                      experience.level
                    }}</span>
                  </div>
                  <div class="spacer-right">
                    <span class="work-detail-bottom-header">
                      Compensation
                    </span>
                    <span
                      class="work-detail-bottom-value"
                      v-if="experience.ctc"
                      >{{ experience.ctc }}</span
                    >
                    <span class="work-detail-bottom-value" v-else>-</span>
                  </div>
                </div>
              </a-card>
            </a-timeline-item>
            <a-timeline-item class="timeline-item">
              <a-card v-if="!experience.current">
                <div class="card-header">
                  <span>{{ experience.company }}</span>
                  <span class="right-header" v-if="experience.from">
                    {{ displayDate(experience.from) }} -
                    {{ displayDate(experience.to) }}

                    <a-popover
                      @click="openEditPopover(index)"
                    >
                      <template #content>
                        <div style="width: 120px">
                          <div
                            @click="
                              onUpdateExperience(
                                experience.id,
                                index,
                                experience
                              )
                            "
                            style="font-weight: bold; cursor: pointer"
                          >
                            Edit
                          </div>
                          <hr />
                          <div
                            @click="onDeleteExperience(experience.id, index)"
                            style="font-weight: bold; cursor: pointer"
                          >
                            Delete
                          </div>
                        </div>
                      </template>
                      <MoreOutlined
                        style="
                          color: #000000;
                          font-size: 20px;
                          font-weight: bold;
                        "
                      />
                    </a-popover>
                  </span>
                </div>
                <p class="middle-section">{{ experience.designation }}</p>
                <div class="card-bottom-section">
                  <div style="margin-right: 6rem">
                    <span class="work-detail-bottom-header"> Reports To </span>
                    <span class="work-detail-bottom-value">{{
                      experience.reportingTo
                    }}</span>
                  </div>
                  <div style="margin-right: 6rem">
                    <span class="work-detail-bottom-header"> Level </span>
                    <span class="work-detail-bottom-value">{{
                      experience.level
                    }}</span>
                  </div>
                  <div class="spacer-right">
                    <span class="work-detail-bottom-header">
                      Compensation
                    </span>
                    <span
                      class="work-detail-bottom-value"
                      v-if="experience.ctc"
                      >{{ experience.ctc }}</span
                    >
                    <span class="work-detail-bottom-value" v-else>-</span>
                  </div>
                </div>
              </a-card>
            </a-timeline-item>
          </a-timeline>
        </div>
      </div>
      <div class="candidate-vito-comments-section">
        <div class="header">
          <span class="title">Vito Comments</span>
        </div>
        <div class="main-section">
          <a-card
            :bordered="false"
            v-for="(note, index) in notesData"
            :key="index"
          >
            <div class="comments-top-section">
              <div>
                <a-avatar shape="circle" :size="50">
                  <template #icon><UserOutlined /></template>
                </a-avatar>
              </div>
              <div class="commentators-details">
                <p class="name">{{ note.name }}</p>
                <p class="position"></p>
              </div>
            </div>
            <p class="comment">{{ note.description }}</p>
          </a-card>
          <!-- <a-divider class="comments-divider" /> -->

          <!-- <a-divider class="comments-divider" />
            
            <a-divider class="comments-divider" /> -->
        </div>
        <a-input
          v-model:value="comments"
          placeholder="Type in Your Comments"
          class="comments-input"
        />
      </div>
    </div>

    <div id="meeting-history">
      <div class="candidate-profile-meeting-history-section">
        <div class="header">
          <span class="title">Meeting History</span>
          <PlusOutlined
            class="add-icon"
            @click="attachVacancy('meeting-history')"
          />
        </div>
        <div class="table-section">
          <a-table
            :columns="meetingColumnList"
            :data-source="meetingData"
            :pagination="false"
            :rowKey="(record) => record.id"
          >
            <template #meetingBy="{ text }">
              <img v-if="text.image" src="{{text.image}}" />
              <a-avatar shape="circle" :size="35" v-else>
                <template #icon><UserOutlined /></template>
              </a-avatar>
              <span class="name">{{ text.name }}</span>
            </template>
            <template #comments="{ text }">
              <PlusOutlined style="margin-right: 2px" />
              <FileOutlined style="margin-right: 3px" />
              <span v-if="text">{{ text }}</span>
              <span v-else>0</span>
            </template>
            <template #action>
              <EllipsisOutlined
                :rotate="90"
                style="color: #000000; font-size: 16px; font-weight: bold"
              />
            </template>
          </a-table>
        </div>
        <div v-if="meetingData.length > 3" class="view-more-btn">
          <a-button
            type="link"
            shape="round"
            class="borderless-btn"
            @click="viewMoreData"
          >
            <template #icon>
              <PlusCircleOutlined />
              View more
            </template>
          </a-button>
        </div>
      </div>
    </div>

    <div id="vacancy-history">
      <div class="candidate-profile-vacancy-history-section">
        <div class="header">
          <span class="title">Vacancy History</span>
        </div>
        <a-tabs v-model:activeKey="vacancyTab" :animated="false">
          <a-tab-pane key="current" tab="Current Vacancy">
            <a-table
              :columns="vacancyColumnList"
              :data-source="vacanciesData"
              :pagination="false"
              :rowKey="(record) => record.vacancyId"
              style="color: #4c5155"
            >
            </a-table>
            <div v-if="vacanciesData.length > 3" class="view-more-btn">
              <a-button
                type="link"
                shape="round"
                class="borderless-btn"
                @click="viewMoreVacancyHistoryData"
              >
                <template #icon>
                  <PlusCircleOutlined />
                  View more
                </template>
              </a-button>
            </div>
          </a-tab-pane>
          <a-tab-pane key="history" tab="Vacancy History" force-render>
            <a-table
              :columns="vacancyColumnList"
              :data-source="vacanciesDataHistory"
              :pagination="false"
              :rowKey="(record) => record.vacancyId"
              style="color: #4c5155"
            >
            </a-table>
            <div v-if="vacanciesDataHistory.length > 3" class="view-more-btn">
              <a-button
                type="link"
                shape="round"
                class="borderless-btn"
                @click="viewMoreCurrentVacancyData"
              >
                <template #icon>
                  <PlusCircleOutlined />
                  View more
                </template>
              </a-button>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>

    <div id="education" class="candidate-profile-education-tags-section">
      <div class="candidate-education-details-section">
        <div class="header">
          <span class="title">Education Details</span>
          <PlusOutlined @click="attachVacancy('education')" class="add-icon" />
        </div>
        <div class="main-section" v-if="data.qualifications[0] && data.qualifications[0].id">
          <a-timeline
            class="timeline"
            v-for="(qualification, index) in data.qualifications"
            :key="index"
          >
            <a-timeline-item
              class="timeline-item"
              v-if="!data.qualificationscurrent"
            >
              <a-card style="height=100px">
                <div class="card-header">
                  <span>{{
                    qualification.institute && qualification.institute.name
                      ? qualification.institute.name
                      : "-"
                  }}</span>
                  <span class="right-header"
                    >{{ qualification.year ? qualification.year : "-" }}
                    <a-popover @mouseenter="openEducationPopover(index)">
                      <template #content>
                        <div style="width: 120px">
                          <div
                            @click="
                              onUpdateEducation(
                                qualification.id,
                                index,
                                qualification
                              )
                            "
                            style="font-weight: bold; cursor: pointer"
                          >
                            Edit
                          </div>
                          <hr />
                          <div
                            @click="onDeleteEducation(qualification.id, index)"
                            style="font-weight: bold; cursor: pointer"
                          >
                            Delete
                          </div>
                        </div>
                      </template>
                      <MoreOutlined
                        style="
                          color: #000000;
                          font-size: 15px;
                          font-weight: bold;
                        "
                      />
                    </a-popover>
                  </span>
                </div>
                <p class="middle-section">
                  {{
                    qualification &&
                    qualification.degree &&
                    qualification.degree.name
                      ? qualification.degree.name
                      : "-"
                  }}
                </p>
              </a-card>
            </a-timeline-item>
            <a-timeline-item class="timeline-item" v-if="qualification.current">
              <a-card v-if="!qualification.current">
                <div class="card-header">
                  <span>{{
                    qualification &&
                    qualification.institute &&
                    qualification.institute.name
                      ? qualification.institute.name
                      : "-"
                  }}</span>
                  <span class="right-header"
                    >{{
                      qualification && qualification.year
                        ? qualification.year
                        : "-"
                    }}
                    <a-popover
                      v-model:visible="educationDetailsVisible"
                      @mouseenter="openEditEducationPopover(index)"
                    >
                      <template #content>
                        <div style="width: 120px">
                          <div
                            @click="
                              onUpdateEducation(
                                qualification.id,
                                index,
                                qualification
                              )
                            "
                            style="font-weight: bold; cursor: pointer"
                          >
                            Edit
                          </div>
                          <hr />
                          <div
                            @click="onDeleteEducation(qualification.id, index)"
                            style="font-weight: bold; cursor: pointer"
                          >
                            Delete
                          </div>
                        </div>
                      </template>
                      <MoreOutlined
                        style="
                          color: #000000;
                          font-size: 20px;
                          font-weight: bold;
                        "
                      />
                    </a-popover>
                  </span>
                </div>
                <p class="middle-section-first">
                  {{ qualification.degree.name }}
                </p>
              </a-card>
            </a-timeline-item>
          </a-timeline>
        </div>
      </div>
      <div class="candidate-profile-tags-section">
        <div class="header">
          <span class="title">Tags</span>
          <PlusOutlined class="add-icon" />
        </div>
        <div class="tag-lists">
          <a-tag
            closable
            color="#00000099"
            v-for="(tags, index) in data.tags"
            :key="index"
            class="tags"
          >
            {{ tags }}
          </a-tag>
        </div>
        <div class="main-section">
          <p>Tagging</p>
          <a-list
            :data-source="tagTitles"
            class="tag-list-view"
            :pagination="false"
          >
            <template #renderItem="{ item }">
              <a-list-item class="tags-list-item">
                <span class="list-title">{{ item.title }}:</span>
                <span v-if="item.value.length > 0">
                  <a-tag
                    closable
                    color="#00000099"
                    v-for="(tags, index) in item.value"
                    :key="index"
                    class="tags"
                  >
                    {{ tags }}
                  </a-tag>
                </span>
              </a-list-item>
            </template>
          </a-list>
        </div>
      </div>
    </div>

    <div id="documents" class="candidate-profile-documents-section">
      <div class="header">
        <span class="title">Documents</span>
        <PlusOutlined @click="attachVacancy('documents')" class="add-icon" />
      </div>
      <div class="table-section">
        <a-table
          :columns="documentColumnList"
          :data-source="documentData"
          :pagination="false"
          :rowKey="(record) => record.id"
        >
          <template #fileSize="{ text }">
            <span>{{ text }} kb</span>
          </template>
        </a-table>
      </div>
    </div>

    <div id="background" class="candidate-profile-background-section">
      <div class="header">
        <span class="title">Background</span>
      </div>
      <div class="text-editor-section">
        <QuillEditor v-model:content="backgroundContent" />
      </div>
      <div class="bottom-part">
        <a-button type="primary">Save</a-button>
      </div>
    </div>

    <div id="aum" class="candidate-profile-aum-section">
      <div class="header">
        <span class="title">AUM</span>
      </div>
      <div class="text-editor-section">
        <QuillEditor v-model:content="aumContent" />
      </div>
      <div class="bottom-part">
        <a-button type="primary">Save</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import AttachVacancy from "./CandidateProfileModal.vue";
import AttachWorkInfo from "./workdetails.vue";
import Utils from "../../../utils";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";

import {
  FileOutlined,
  EllipsisOutlined,
  UserOutlined,
  PlusOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
  TwitterSquareFilled,
  LinkedinFilled,
  FacebookFilled,
  PlusCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons-vue";
import { ref, onMounted, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import Services from "@/services/apis";
import { QuillEditor, Delta } from "@vueup/vue-quill";
import ROUTER from "../../../services/apis/router";

const documentColumnList = [
  {
    title: "SR#",
    dataIndex: "sr",
    width: 70,
  },
  {
    title: "File Name",
    dataIndex: "fileName",
    key: "fileName",
    align: "center",
  },
  {
    title: "File Size",
    dataIndex: "fileSize",
    slots: { customRender: "fileSize" },
    key: "fileSize",
    align: "center",
  },
  {
    title: "Doc Type",
    dataIndex: "docType",
    key: "docType",
    align: "center",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    align: "center",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    align: "center",
  },
];
const router = useRouter();
const meetingColumnList = [
  {
    title: "SR#",
    dataIndex: "sr",
    key: "sr",
    width: 70,
  },
  {
    title: "Meeting Type",
    dataIndex: "meetingType",
    key: "meetingType",
    width: 150,
  },
  {
    title: "Meeting By",
    dataIndex: "meetingBy",
    slots: { customRender: "meetingBy" },
    key: "meetingBy",
    width: 300,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Team",
    dataIndex: "team",
    key: "team",
    align: "center",
  },
  {
    title: "Sub Team",
    dataIndex: "subTeam",
    key: "subTeam",
    align: "center",
  },
  {
    title: "Level",
    dataIndex: "level",
    key: "level",
    align: "center",
  },
  {
    title: "Comments",
    dataIndex: "comments",
    slots: { customRender: "comments" },
    key: "comments",
    align: "center",
  },
  {
    dataIndex: "action",
    key: "action",
    width: 30,
    slots: { customRender: "action" },
    align: "center",
  },
];
let modalform = ref("");
const editProfile = (id) => {
  router.push(`/edit-candidate/${id}`);
};
const vacancyColumnList = [
  {
    title: "SR#",
    dataIndex: "sr",
    key: "sr",
    width: 70,
  },
  {
    title: "Vacancy ID",
    dataIndex: "vacancyId",
    key: "vacancyId",
    align: "center",
  },
  {
    title: "Account",
    dataIndex: "account",
    key: "account",
    align: "center",
  },
  {
    title: "Vacancy Title",
    dataIndex: "vacancyTitle",
    key: "vacancyTitle",
    align: "center",
  },
  {
    title: "Last Procedure",
    dataIndex: "lastProcedure",
    key: "lastProcedure",
    align: "center",
  },
  {
    title: "Procedure Date",
    dataIndex: "procedureDate",
    key: "procedureDate",
    align: "center",
  },
];
let data = reactive({
  address: {
    id: "",
    type: "",
    street: "",
    zip: "",
  },
  socialAccounts: { facebook: {}, skype: {}, twitter: {}, linkedIn: {} },
  id: "",
  resume: { resumeId: "", status: "" },
  qualifications: [
    {
      id: "",
      degree: {
        _id: "",
        id: "",
        name: "",
      },
      institute: {
        id: "",
        name: "",
      },
      month: "",
      year: "",
      current: false,
    },
  ],
  experiences: [
    {
      id: "",
      company: "",
      from: "",
      to: "",
      designation: "",
      level: "",
      ctc: "",
      products: [],
      current: false,
      reportingTo: "",
      reasonForLeaving: "",
      duties: "",
      achievements: "",
    },
  ],
  tags: [],
  rating: 0,
  userId: "",
  name: "",
  totalWorkExp: null,
  relationshipManager: null,
  ctc: "",
  workplaceCity: null,
  imageUrls: {},
  notesCount: "",
  documentsCount: 0,
  status: "",
  source: "",
  background: { text: "", editorContent: "" },
  aum: { text: "", editorContent: "" },
  verticals: [{ id: "", name: "" }],
  practiceAreas: [{ id: "", name: "" }],
  subFunctions: [{ id: "", name: "" }],
  subPracticeAreas: [{ id: "", name: "" }],
  subSubFunctions: [{ id: "", name: "" }],
});

let notesData = reactive([]);

let widgetsData = reactive({
  jobOffered: 0,
  interviews: 0,
  rejected: 0,
});

let meetingData = reactive([]);

let documentData = reactive([]);

let vacanciesData = reactive([]);
let vacanciesDataHistory = reactive([]);

export default {
  components: {
    PlusCircleOutlined,
    FileOutlined,
    // AttachWorkInfo,
    EllipsisOutlined,
    UserOutlined,
    PlusOutlined,
    AttachVacancy,
    CloseOutlined,
    EditOutlined,
    DeleteOutlined,
    TwitterSquareFilled,
    LinkedinFilled,
    FacebookFilled,
    QuillEditor,
    MoreOutlined,
  },
  setup: (props, context) => {
    const visible = ref(false);
    const workDetailsVisible = ref(false);
    const workDetailsVisibleFirst = ref(false);
    const educationDetailsVisible = ref(false);
    const educationDetailsVisibleFirst = ref(false);
    const onSubmit = () => {
      visible.value = false;
      getCandidateMeetings();
    };
    let currentPageNum = 0;
    let currentPageNumOfHisVacancy = 1;
    let currentPageNumOfCurrVacancy = 1;

    const viewMoreData = () => {
      currentPageNum++;
      getCandidateMeetings();
    };
    const viewMoreCurrentVacancyData = () => {
      currentPageNumOfCurrVacancy++;
      getCurrentCandidateVacancies();
    };
    const viewMoreVacancyHistoryData = () => {
      currentPageNumOfHisVacancy++;
      getCandidateVacancies();
    };

    var shiftWindow = function () {
      scrollBy(0, -205);
    };

    if (location.hash) {
      shiftWindow();
      window.addEventListener("hashchange", shiftWindow);
    }

    let deleteWorkData = reactive({});
    const store = useStore();
    const onAddWorkDetails = (workData) => {
      visible.value = false;
      if (
        computed(() => store.getters["candidateStore/getCandidateFormState"])
          .value === "edit"
      ) {
        workData["id"] = computed(
          () => store.getters["candidateStore/getExperienceIdOfCandidate"]
        ).value;
        data.experiences.splice(selectedPopoverIndex, 1, workData);
      } else {
        data.experiences.push(workData);
      }
    };

    const onAddEducation = (EducData) => {
      visible.value = false;
      if (
        computed(
          () => store.getters["candidateStore/getCandidateEducationFormState"]
        ).value === "edit"
      ) {
        EducData["id"] = computed(
          () => store.getters["candidateStore/getEducationIdOfCandidate"]
        ).value;
        data.qualifications.splice(selectedPopoverIndexEducation, 1, EducData);
        getCandidateProfile(id);
      } else {
        data.qualifications.push(EducData);
        getCandidateProfile(id);
      }
      getCandidateProfile(id);
    };

    const getEditWorkDetailsFormData = (tempData, closePopup) => {
      editDeletevisible.value = false;
      visible.value = closePopup;
      // data.experiences.push(tempData);
    };

    const getEditEducationFormData = (tempData, closePopup) => {
      editDeletevisible.value = false;
      visible.value = closePopup;
      // data.experiences.push(tempData);
    };

    const onCancel = (hideModal) => {
      visible.value = hideModal;
    };

    let experienceId = "";

    let selectedPopoverIndex = null;
    let selectedPopoverIndexEdit = null;
    let selectedPopoverIndexEducation = null;
    let selectedEducationPopoverIndex = null;

    const {
      params: { id },
    } = useRoute();
    let dataSource3 = reactive([]);
    let tagTitles = reactive([
      { title: "Verticals", value: [], id: "verticals" },
      { title: "Practice Area", value: [], id: "practiceAreas" },
      { title: "Sub Practice Area", value: [], id: "subPracticeAreas" },
      { title: "Function", value: [], id: "functions" },
      { title: "Sub-Function", value: [], id: "sfWorkAreas" },
      { title: "Sub-SubFunction", value: [], id: "ssfTags" },
      { title: "Product", value: [], id: "products" },
      { title: "Segment", value: [], id: "segments" },
      { title: "Region", value: [], id: "regions" },
      { title: "Sector", value: [], id: "sectors" },
    ]);
    const editDeletevisible = ref(false);
    let backgroundContent = ref();
    let aumContent = ref();
    const attachVacancy = (form, type) => {
      store.dispatch("candidateStore/getCandidateWorkDetailsFormState", type);
      modalform.value = form;
      visible.value = true;
      editDeletevisible.value = true;
    };

    function onDeleteExperience(experienceId, index) {
      workDetailsVisible.value = false;
      let payload = {
        id: id,
        experienceId: experienceId,
      };

      store.dispatch(
        "candidateStore/getCandidateWorkExperienceId",
        experienceId
      );

      Services.deleteCandidateExperience(payload)
        .then((response) => {
          if (response.status === 200) {
            Object.assign(data, response.data);

            editDeletevisible.value = false;
            data.experiences.splice(index, 1);
            message.success("Deleted successfully");
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    }
    function onDeleteEducation(qualificationId, index) {
      educationDetailsVisible.value = false;
      let payload = {
        id: id,
        qualificationId: qualificationId,
      };

      store.dispatch("candidateStore/getCandidateEducationId", qualificationId);

      Services.deleteCandidateEducation(payload)
        .then((response) => {
          if (response.status === 200) {
            Object.assign(data, response.data);

            editDeletevisible.value = false;
            data.qualifications.splice(index, 1);
            message.success("Deleted successfully");
          }
          console.log("data :>> ", data);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    }

    function onUpdateExperience(experienceId, index, experience) {
      workDetailsVisible.value = false;
      workDetailsVisibleFirst.value = false;
      let payload = {
        id: id,
        experienceId: experienceId,
        data: {
          company: experience.company,
          ctc: experience.ctc,
          designation: experience.designation,
          from: Utils.displayYearFirstNew(experience.from),
          level: experience.level,
          reportingTo: experience.reportingTo,
          to: Utils.displayYearFirstNew(experience.to),
          // current: experience.current,
        },
      };

      store.dispatch(
        "candidateStore/getCandidateWorkDetailsFormData",
        payload.data
      );
      store.dispatch(
        "candidateStore/getCandidateWorkExperienceId",
        experienceId
      );

      attachVacancy("workinfo", "edit", payload.data);
      Services.updateCandidateExperience(payload)
        .then((response) => {
          if (response.status === 200) {
            Object.assign(data, response.data);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    }
    function onUpdateEducation(qualificationId, index, education) {
      educationDetailsVisible.value = false;
      educationDetailsVisibleFirst.value = false;
      editDeletevisible.value = false;
      visible.value = false;
      let payload = {
        id: id,
        qualificationId: qualificationId,
        data: {
          instituteId: education.institute.id,
          degreeId: education.degree._id,
          year: education.year,
        },
      };

      store.dispatch(
        "candidateStore/getCandidateEducationFormData",
        payload.data
      );
      store.dispatch(
        "candidateStore/getCandidateEducationDetailId",
        qualificationId
      );

      attachVacancy("education", "edit", payload.data);
      Services.updateCandidateEducation(payload)
        .then((response) => {
          if (response.status === 200) {
            Object.assign(data, response.data);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    }

    const getCandidateProfile = (id) => {
      Services.candidateProfile(id)
        .then((response) => {
          if (response.status === 200) {
            Object.assign(data, response.data);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const getCandidateNotes = (id) => {
      let tempData = [];
      Services.candidateNotes(id)
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;
            data &&
              data[0].count &&
              data[0].notes.map((note) => {
                tempData.push({
                  id: note.id,
                  name: note.createdBy.name,
                  title: note.title,
                  description: note.description,
                  createdAt: note.createdAt,
                });
              });
            Object.assign(notesData, tempData);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    // const getCandidateWidgets = (id) => {
    //   Services.candidateWidgets(id)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         Object.assign(widgetsData, response.data);
    //       }
    //     })
    //     .catch((e) => {
    //       console.log("error :>> ", e);
    //     });
    // };
    const getCandidateMeetings = (id) => {
      let tempData = [];
      let payload;
      Services.candidateMeetings(id, (payload = { pageNo: currentPageNum }))
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;
            data &&
              data[0].count &&
              data[0].meetings.map((meeting) => {
                tempData.push({
                  sr: tempData.length + 1,
                  id: meeting.id,
                  meetingType: meeting.type,
                  meetingBy: {
                    image: meeting.meetingBy.image,
                    name: meeting.meetingBy.name,
                  },
                  team: meeting.teams.map((team) => team.name),
                  subTeam: meeting.subTeams.map((subTeam) => subTeam.name),
                  level: meeting.level.name,
                  comments: meeting.notes,
                  date: Utils.displayLongDate(meeting.startTimme),
                });
              });
            Object.assign(meetingData, tempData);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const getCandidateDocuments = (id) => {
      let tempData = [];
      Services.candidateDocuments(id)
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;
            data &&
              data[0].count &&
              data[0].documents.map((document) => {
                tempData.push({
                  sr: tempData.length + 1,
                  id: document.id,
                  fileName: document.name,
                  fileSize: document.size,
                  docType: document.type,
                  date: displayDate(document.createdAt),
                  description: document.description,
                });
              });
            Object.assign(documentData, tempData);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const getCandidateTaggings = (id) => {
      Services.candidateTaxonomy(id)
        .then((response) => {
          if (response.status === 200) {
            let { taxonomy } = response.data;
            tagTitles.map((tag) => {
              let tagArray = taxonomy[tag.id];
              tagArray.map((x) => {
                tag.value.push(x.name);
              });
            });
          }
        })
        .catch((e) => {
          if (e && e.response) {
            console.log("error :>> ", e);
          }
        });
    };
    const getCandidateVacancies = (id) => {
      let historyData = [];
      let payload = {
        id: id,
        pageNo: currentPageNumOfHisVacancy,
        type: "HISTORY",
      };
      Services.candidateVacancies(payload)
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;
            data &&
              data[0].count &&
              data[0].vacancies.map((x) => {
                historyData.push({
                  sr: historyData.length + 1,
                  vacancyId: x.vacancy.vacancyId ? x.vacancy.vacancyId : "-",
                  account: x.account.name ? x.account.name : "-",
                  vacancyTitle: x.vacancy.title ? x.vacancy.title : "-",
                  lastProcedure: x.procedure.name ? x.procedure.name : "-",
                  procedureDate: x.procedure.date
                    ? Utils.displayLongDate(x.procedure.date)
                    : "-",
                });
              });
            Object.assign(vacanciesDataHistory, historyData);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const getCurrentCandidateVacancies = (id) => {
      let tempData = [];
      let payload = {
        id: id,
        pageNo: currentPageNumOfCurrVacancy,
        type: "CURRENT",
      };
      Services.candidateVacancies(payload)
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;

            data &&
              data[0].count &&
              data[0].vacancies.map((x) => {
                tempData.push({
                  sr: tempData.length + 1,
                  vacancyId: x.vacancy.vacancyId ? x.vacancy.vacancyId : "-",
                  account: x.account && x.account.name ? x.account.name : "-",
                  vacancyTitle: x.vacancy.title ? x.vacancy.title : "-",
                  lastProcedure: x.procedure.name ? x.procedure.name : "-",
                  procedureDate: x.procedure.date
                    ? Utils.displayLongDate(x.procedure.date)
                    : "-",
                });
              });
            Object.assign(vacanciesData, tempData);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const handleMenuClick = (event) => {
      current = [event.key];
    };

    function displayDate(value) {
      return dayjs(value).format("DD/MM/YYYY");
    }

    function attachCandidateToVacancyInProfile() {
      store.dispatch("candidateStore/getcandidateIds", id);
      // store.dispatch("candidateStore/attachCandidateToVacancyInProfile", id);
      visible.value = false;
    }

    function openPopover(index) {
      selectedPopoverIndex = index;
      editDeletevisible.value = false;
    }

    function openEditPopover(index) {
      selectedPopoverIndexEdit = index;
      editDeletevisible.value = false;
    }

    function openEditEducationPopover(index) {
      editDeletevisible.value = false;
      selectedEducationPopoverIndex = index;
      console.log(
        "selectedPopoverIndexEducation :>> ",
        selectedPopoverIndexEducation
      );
    }

    function openEducationPopover(index) {
      educationDetailsVisibleFirst.value = true;
      selectedPopoverIndexEducation = index;
      editDeletevisible.value = false;
    }
    onMounted(() => {
      getCandidateProfile(id);
      getCandidateNotes(id);
      // getCandidateWidgets(id);
      getCandidateMeetings(id);
      getCandidateDocuments(id);
      getCandidateTaggings(id);
      getCandidateVacancies(id);
      Object.assign(backgroundContent, data.background.editorContent);
      Object.assign(aumContent, data.aum.editorContent);
      getCurrentCandidateVacancies(id);
      shiftWindow();
    });

    return {
      backgroundContent,
      aumContent,
      currentPageNum,
      onDeleteEducation,
      rating: ref(data.rating),
      documentColumnList,
      onUpdateEducation,
      onAddEducation,
      modalform,
      AttachWorkInfo,
      visible,
      meetingColumnList,
      attachVacancy,
      vacancyColumnList,
      dataSource3,
      vacancyTab: ref("current"),
      activeKey: ref("1"),
      tagTitles,
      comments: "",
      current: ["about"],
      data,
      editProfile,
      id,
      notesData,
      widgetsData,
      meetingData,
      documentData,
      vacanciesData,
      vacanciesDataHistory,
      handleMenuClick,
      displayDate,
      attachCandidateToVacancyInProfile,
      onSubmit,

      onAddWorkDetails,
      editDeletevisible,
      onDeleteExperience,
      experienceId,
      deleteWorkData,
      selectedPopoverIndex,
      openPopover,
      onUpdateExperience,
      getEditWorkDetailsFormData,
      onCancel,
      viewMoreData,
      viewMoreCurrentVacancyData,
      viewMoreVacancyHistoryData,
      getEditEducationFormData,
      workDetailsVisible,
      educationDetailsVisible,
      educationDetailsVisibleFirst,
      workDetailsVisibleFirst,
      openEditPopover,
      selectedPopoverIndexEdit,
      selectedPopoverIndexEducation,
      openEditEducationPopover,
      openEducationPopover,
      selectedEducationPopoverIndex,
      // workData
      // displayLongDate,
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/features/candidate/styles/candidateProfile.scss";
</style>
