<template>
  <div v-if="props.formName == 'candidate'" class="modal-form">
    <div class="modal-header">
      <h3>Attach Candidate to Vacancy</h3>
    </div>
    <div>
      <div class="modal-data"><span>Vacancy</span></div>
      <div class="modal-input">
        <a-select
          show-search
          placeholder="Select"
          class="select-input-white-bg"
          style="width: 100%"
          v-model:value="modelRef.vacancyId"
          :filter-option="false"
          :options="vacancy_name_options"
          @search="fetchVacancyListing"
          @change="onSelectVacancyChange"
        >
        </a-select>
      </div>
      <div class="modal-data">Procedure Stage</div>
      <div class="modal-input">
        <a-select
          v-model:value="modelRef.procedureStageId"
          style="width: 100%"
          placeholder="Select"
          class="select-input-white-bg"
          :options="procedure_stage_tags_options"
          @change="onProcedureStageChange"
        >
        </a-select>
      </div>
    </div>
    <div class="dashboard-cancel-and-export-button-wrapper">
      <div class="dashboard-cancel-button" @click="onCancel()" >
        Cancel
      </div>
      <div class="">
        <a-button type="primary" @click="attachCandidateToVacancyInProfile"
          >Save</a-button
        >
      </div>
    </div>
  </div>

  <!-- Work Details Modal  -->

  <div v-if="props.formName == 'workinfo'" class="modal-form">
    <div class="modal-header">
      <h3>Work Details</h3>
    </div>
    <div>
      <div class="modal-data"><span>Company Name</span></div>
      <div class="modal-input">
        <a-input
          class="form-input"
          v-model:value="modelRef.company"
          placeholder="Enter"
        ></a-input>
      </div>
      <div class="modal-data">Designation</div>
      <div class="modal-input">
        <a-input
          class="form-input"
          v-model:value="modelRef.designation"
          placeholder="Enter"
        ></a-input>
      </div>
      <div class="modal-data">Reports To</div>
      <div class="modal-input">
        <a-input
          class="form-input"
          v-model:value="modelRef.reportingTo"
          placeholder="Enter"
        ></a-input>
      </div>
      <div class="modal-text-input">
        <div class="modal-input" style="width: 100%; margin-right: 10px">
          <div class="modal-data">Level</div>
          <a-input
            class="form-input"
            v-model:value="modelRef.workLevel"
            placeholder="Enter"
          ></a-input>
        </div>
        <div class="modal-input" style="width: 100%">
          <div class="modal-data">Compensation</div>
          <a-input
            class="form-input"
            v-model:value="modelRef.ctc"
            placeholder="Enter"
          ></a-input>
        </div>
      </div>
      <div class="modal-text-input">
        <div class="modal-input" style="width: 100%; margin-right: 10px">
          <div class="modal-data">From Date</div>
          <a-date-picker
            class="date-input"
            placeholder="From Date"
            style="width: 95%; height: 2.9rem; border-radius: 15px !important"
            v-model:value="modelRef.from"
          ></a-date-picker>
        </div>
        <div class="modal-input" style="width: 100%">
          <div class="modal-data">To Date</div>
          <a-date-picker
            class="date-input"
            placeholder="From Date"
            style="width: 95%; height: 2.9rem; border-radius: 15px !important"
            v-model:value="modelRef.to"
          ></a-date-picker>
          <a-checkbox
            style="margin-top: 0.5rem"
            v-model:checked="modelRef.current"
            >Present</a-checkbox
          >
        </div>
      </div>
      <div class="candidate-footer-modal">
        <div style="margin-left: auto">
          <a-button
            type=""
            @click="onCancel()"
            style="color: black; opacity: 0.7"
            >Cancel</a-button
          >
        </div>
        <div>
          <a-button @click="onAddWorkDetails()" type="primary">Save</a-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Meeting History Modal-->
  <a-form :ref="formRef" scrollToFirstError>
    <div v-if="props.formName == 'meeting-history'" class="modal-form">
      <div class="modal-header">
        <h3>Meeting History</h3>
      </div>
      <div>
        <div class="modal-text-input">
          <div class="modal-input" style="width: 100%; margin-right: 10px">
            <a-form-item v-bind="validateInfos.date">
              <div class="modal-data">Date</div>
              <a-date-picker
                ref="select"
                style="width: 100%"
                class="candidate-profile-meeting-date"
                placeholder="Select"
                v-model:value="modelRef.date"
                @focus="focus"
              >
              </a-date-picker>
            </a-form-item>
          </div>
          <div class="modal-input" style="width: 100%">
            <div class="modal-data">Meeting Type</div>
            <a-form-item v-bind="validateInfos.type">
              <a-select
                ref="select"
                style="width: 100%"
                placeholder="Select"
                class="select-input-white-bg"
                :options="meetingOptions"
                v-model:value="modelRef.type"
                @focus="focus"
              >
              </a-select>
            </a-form-item>
          </div>
        </div>
        <div class="modal-data"><span>Meeting By</span></div>
        <div class="modal-input">
          <a-form-item v-bind="validateInfos.meeting_by">
            <a-select
              ref="select"
              style="width: 100%"
              placeholder="Select"
              class="select-input-white-bg"
              :options="meetingbyOptions"
              v-model:value="modelRef.meeting_by"
              @focus="focus"
            >
            </a-select>
          </a-form-item>
        </div>
        <div class="modal-text-input">
          <div class="modal-input" style="width: 100%; margin-right: 10px">
            <div class="modal-data">Team</div>
            <a-form-item v-bind="validateInfos.teamIds">
              <a-select
                ref="select"
                style="width: 100%"
                placeholder="Select"
                class="select-input-white-bg"
                :options="meetingTeamsOptions"
                v-model:value="modelRef.teamIds"
                @change="getSubteam(String(teamIds))"
              >
              </a-select>
            </a-form-item>
          </div>
          <div class="modal-input" style="width: 100%">
            <div class="modal-data">Sub Team</div>
            <a-form-item v-bind="validateInfos.subTeamIds">
              <a-select
                ref="select"
                style="width: 100%"
                placeholder="Select"
                class="select-input-white-bg"
                :options="meetingSub_teamsOptions"
                v-model:value="modelRef.subTeamIds"
                @focus="focus"
              >
              </a-select>
            </a-form-item>
          </div>
        </div>
        <div class="modal-data"><span>Level</span></div>
        <div class="modal-input">
          <a-form-item v-bind="validateInfos.level">
            <a-select
              ref="select"
              style="width: 100%"
              placeholder="Select"
              class="select-input-white-bg"
              :options="meetinglevelOptions"
              v-model:value="modelRef.level"
              @focus="focus"
            >
            </a-select>
          </a-form-item>
        </div>
        <div class="modal-input" style="width: 100%">
          <div class="modal-data">Comments</div>
          <a-textarea
            v-model:value="modelRef.remarks"
            placeholder="Enter"
            :auto-size="{ minRows: 2, maxRows: 5 }"
          />
        </div>
        <div class="candidate-footer-modal">
          <div style="margin-left: auto">
            <a-button
              type=""
              @click="destroymodal()"
              style="color: black; opacity: 0.7"
              >Cancel</a-button
            >
          </div>
          <div>
            <a-button @click="onSubmit" type="primary">Save</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-form>

  <div v-if="props.formName == 'education'" class="modal-form">
    <div class="modal-header">
      <h3>Educational Details</h3>
    </div>
    <div>
      <div class="modal-data"><span>University Name</span></div>
      <div class="modal-input">
        <a-select
          v-model:value="modelRef.instituteId"
          :options="instituteOptions"
          class="select-input"
          placeholder="Enter"
        ></a-select>
      </div>
      <div class="modal-data">Degree</div>
      <div class="modal-input">
        <a-select
          class="select-input"
          :options="eduDegreesOptions"
          v-model:value="modelRef.degreeId"
          placeholder="Select"
        ></a-select>
      </div>
      <div class="modal-data">Year</div>
      <div class="modal-input">
        <a-date-picker
          class="date-input"
          placeholder="From Date"
          style="width: 95%; height: 2.9rem; border-radius: 15px !important"
          v-model:value="modelRef.year"
        ></a-date-picker>
        <a-checkbox style="margin-top: 0.5rem" v-model:checked="modelRef.recent"
          >Most Recent</a-checkbox
        >
      </div>
      <div class="candidate-footer-modal">
        <div style="margin-left: auto">
          <a-button
            type=""
            @click="onCancel()"
            style="color: black; opacity: 0.7"
            >Cancel</a-button
          >
        </div>
        <div>
          <a-button @click="onAddEducation()" type="primary">Save</a-button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="props.formName == 'documents'" class="modal-form">
    <div class="modal-header">
      <h3>Documents</h3>
    </div>
    <div>
      <div class="modal-data"><span>Upload</span></div>
      <div class="modal-input">
        <a-upload-dragger
          v-model:fileList="fileList"
          name="file"
          :multiple="true"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          @change="handleChange"
        >
          <p class="ant-upload-drag-icon">
            <inbox-outlined></inbox-outlined>
          </p>
          <b>
            <p class="ant-upload-text">Upload Documents</p>
          </b>
          <p class="ant-upload-hint">Click or Drag and Drop to Upload</p>
        </a-upload-dragger>
      </div>
      <div class="modal-data"><span>Document Type</span></div>
      <div class="modal-input">
        <a-input
          style="width: 100%"
          class="form-input"
          placeholder="Enter"
        ></a-input>
      </div>
      <div class="modal-data">Date</div>
      <div class="modal-input">
        <a-select
          style="width: 100%"
          placeholder="Select"
          mode="multiple"
          class="select-input-white-bg"
          :options="options"
          v-model:value="value1"
          @change="handleChange"
        >
        </a-select>
      </div>
      <div class="modal-data">Description</div>
      <div class="modal-input">
        <a-textarea
          v-model:value="value2"
          placeholder="Enter"
          :auto-size="{ minRows: 2, maxRows: 5 }"
        />
      </div>
      <div class="candidate-footer-modal">
        <a-button type="">Cancel</a-button>
        <a-button type="primary">Save</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import Services from "@/services/apis";
import { InboxOutlined } from "@ant-design/icons-vue";
import { Form, message, Modal } from "ant-design-vue";
import { useRoute } from "vue-router";
import Utils from "../../../utils";
import { useStore } from "vuex";

const useForm = Form.useForm;
export default defineComponent({
  components: {
    InboxOutlined,
  },

  props: ["formName"],
  setup(props, context) {
    let meetingTeamsOptions = ref([]);
    let meetingOptions = ref([]);
    let meetingSub_teamsOptions = ref([]);
    // let teamIds = ref([]);
    let eduDegreesOptions = ref([]);
    let instituteOptions = ref([]);
    let meetinglevelOptions = ref([]);
    let meetingbyOptions = ref([]);
    const formRef = ref();
    let showWorkDetails = false;
    let workData = reactive({});
    let EducData = reactive({});
    let apiData = reactive({});
    let formState = reactive("");
    let formData = ref();
    let candidateExperienceId = reactive("");
    let candidateEducationId = reactive("");
    let vacancy_name_options = ref([]);
    let procedure_stage_tags_options = ref([]);
    let currentPageNumber = 0;
    const store = useStore();
    function destroymodal() {
      // showWorkDetails = false;
      visible.value = false;
      Modal.destroyAll();
    }

    const {
      params: { id },
    } = useRoute();
    const modelRef = reactive({
      meeting_by: ref([]),
      type: ref([]),
      date: ref(),
      teamIds: ref([]),
      subTeamIds: ref([]),
      remarks: ref([]),
      level: ref([]),
      category: "CANDIDATE",
      candidateId: id,

      //Work Details
      company: "",
      designation: "",
      workLevel: "",
      reportingTo: "",
      from: "",
      to: "",
      ctc: "",
      current: false,

      // Education Details
      instituteId: "",
      degreeId: "",
      year: "",
      recent: ref(false),
    });
    const rulesRef = reactive({
      meeting_by: [
        {
          required: true,
          message: "Please select Meeting by",
        },
      ],
      date: [
        {
          required: true,
          message: "Please select Meeting date",
        },
      ],
      type: [
        {
          required: true,
          message: "Please select Meeting type",
        },
      ],
      teamIds: [
        {
          required: true,
          message: "Please select Meeting team",
        },
      ],
      level: [
        {
          required: true,
          message: "Please select Meeting level",
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    );
    const getSubteam = (id) => {
      Services.teamListing({
        teams: [id],
        types: ["SUB_TEAM"],
      })
        .then((response) => {
          const { data } = response;

          let tempTeams = [];
          data.data.map((x) => {
            tempTeams.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(meetingSub_teamsOptions.value, tempTeams);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const handleChange = (info) => {
      const status = info.file.status;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    };
    const meetingTeamfunct = (value) => {
      Services.teamListing({ mode: "AUTOCOMPLETE", types: "TEAM" })
        .then((response) => {
          const { data } = response;

          meetingTeamsOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const meetingLevelfunct = (value) => {
      Services.getlevelDetail({ mode: "AUTOCOMPLETE" })
        .then((response) => {
          const { data } = response;

          let tempTeams = [];
          data[0].data.map((x) => {
            tempTeams.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(meetinglevelOptions.value, tempTeams);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const meetinguserfunct = (value) => {
      Services.userListing({ pageNo: 0 })
        .then((response) => {
          const { data } = response;

          let tempTeams = [];
          data.data.map((x) => {
            tempTeams.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(meetingbyOptions.value, tempTeams);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    onMounted(() => {
      getEditWorkDetailsFormData();
      getEditEducationFormData();
      meetingTeamfunct();
      meetingLevelfunct();
      meetinguserfunct();
      fetchVacancyListing();
      fetchProcedureStageData();
      Services.genderListing({ mode: "AUTOCOMPLETE", types: "MEETING_TYPES" })
        .then((response) => {
          const { data } = response;

          let tempTeams = [];
          data[0].data.map((x) => {
            tempTeams.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(meetingOptions.value, tempTeams);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    });
    Services.eduInstituteListing()
      .then((response) => {
        const { data } = response;

        let tempTeams = [];
        data[0].data.map((x) => {
          tempTeams.push({
            value: x.id,
            label: x.name,
          });
        });
        Object.assign(instituteOptions.value, tempTeams);
      })
      .catch((e) => {
        console.log("error :>> ", e);
      });
    Services.eduDegreesListing()
      .then((response) => {
        const { data } = response;

        let tempTeams = [];
        data[0].data.map((x) => {
          tempTeams.push({
            value: x.id,
            label: x.name,
          });
        });
        Object.assign(eduDegreesOptions.value, tempTeams);
      })
      .catch((e) => {
        console.log("error :>> ", e);
      });
    const onSubmit = () => {
      validate()
        .then((result) => {
          modelRef.teamIds = [modelRef.teamIds];
          Services.getMeetings(modelRef).then((response) => {
            const { data } = response;
            context.emit("onSubmit");
            message.success("Meeting created successfully");
            // location.reload();
          });
        })
        .catch((err) => {});
    };

    const onCancel = () => {
      context.emit("onCancel", false);
    };

    function onAddWorkDetails() {
      let payload = {};
      if (formState === "edit") {
        payload = {
          id: id,
          experienceId: candidateExperienceId,
          data: {
            company: modelRef.company,
            designation: modelRef.designation,
            level: modelRef.workLevel,
            reportingTo: modelRef.reportingTo,
            from: Utils.displayYearFirstNew(modelRef.from),
            to: Utils.displayYearFirstNew(modelRef.to),
            ctc: modelRef.ctc,
            current: modelRef.current,
          },
        };

        console.log("Editpayload:>> ", payload);

        Services.updateCandidateExperience(payload)
          .then((response) => {
            const { data } = response;
            workData = {
              company: modelRef.company,
              ctc: modelRef.ctc,
              designation: modelRef.designation,
              from: modelRef.from,
              level: modelRef.workLevel,
              reportingTo: modelRef.reportingTo,
              to: modelRef.to,
              id: modelRef.id,
              current: modelRef.current,
            };

            context.emit("onAddWorkDetails", workData);
            message.success("Edited successfully");

            resetFormFields();
          })
          .catch((e) => {
            console.log("error :>> ", e);
          });
      } else {
        payload = {
          id: id,
          data: {
            company: modelRef.company,
            designation: modelRef.designation,
            level: modelRef.workLevel,
            reportingTo: modelRef.reportingTo,
            from: Utils.displayYearFirstNew(modelRef.from),
            to: Utils.displayYearFirstNew(modelRef.to),
            ctc: modelRef.ctc,
            current: modelRef.current ? true : false,
          },
        };

        Services.addCandidateExperience(payload)
          .then((response) => {
            const { data } = response;

            workData = {
              company: data.company,
              ctc: data.ctc,
              designation: data.designation,
              from: data.from,
              level: data.level,
              reportingTo: data.reportingTo,
              to: data.to,
              id: data.id,
              current: data.current,
            };

            context.emit("onAddWorkDetails", workData);
            message.success("Experience added successfully");
          })
          .catch((e) => {
            console.log("error :>> ", e);
          });
      }

      context.emit("getEditWorkDetailsFormData", {}, false);
    }

    function onAddEducation() {
      let payload = {};
      if (formState === "edit") {
        payload = {
          id: id,
          qualificationId: candidateEducationId,
          data: {
            instituteId: modelRef.instituteId,
            degreeId: modelRef.degreeId,
            year: Utils.displayYearFirstNew(modelRef.year),
            recent: modelRef.recent,
          },
        };
        Services.updateCandidateEducation(payload)
          .then((response) => {
            const { data } = response;
            EducData = {
              instituteId: modelRef.instituteId,
              degreeId: modelRef.degreeId,
              year: modelRef.year,
              recent: modelRef.recent,
            };

            context.emit("On Education Details", EducData);
            message.success("Edited successfully");

            resetFormFields();
          })
          .catch((e) => {
            console.log("error :>> ", e);
          });
      } else {
        payload = {
          id: id,
          data: {
            instituteId: modelRef.instituteId,
            degreeId: modelRef.degreeId,
            year: Utils.displayYearFirstNew(modelRef.year),
            recent: modelRef.recent,
          },
        };
        Services.addCandidateEducation(payload)
          .then((response) => {
            const { data } = response;

            EducData = {
              instituteId: modelRef.instituteId,
              degreeId: modelRef.degreeId,
              year: Utils.displayYearFirstNew(modelRef.year),
              recent: modelRef.recent,
            };

            context.emit("onAddEducation", EducData);
            message.success("Education details added successfully");
          })
          .catch((e) => {
            console.log("error :>> ", e);
          });
      }

      context.emit("getEditEducationFormData", {}, false);
    }

    let getEditWorkDetailsFormData = () => {
      formState = computed(
        () => store.getters["candidateStore/getCandidateFormState"]
      ).value;

      let tempData = computed(
        () => store.getters["candidateStore/getCandidateFormData"]
      ).value;

      candidateExperienceId = computed(
        () => store.getters["candidateStore/getExperienceIdOfCandidate"]
      ).value;

      Object.assign(formData, tempData);

      if (formState === "edit") {
        if (props.formName === "workinfo") {
          modelRef.company = tempData.company;
          modelRef.designation = tempData.designation;
          modelRef.workLevel = tempData.level;
          modelRef.reportingTo = tempData.reportingTo;
          modelRef.from = Utils.displayYearFirstNew(tempData.from);
          modelRef.to = Utils.displayYearFirstNew(tempData.to);
          modelRef.ctc = tempData.ctc;
        }
      }
    };
    let getEditEducationFormData = () => {
      formState = computed(
        () => store.getters["candidateStore/getCandidateFormState"]
      ).value;

      let tempData = computed(
        () => store.getters["candidateStore/getCandidateFormData"]
      ).value;

      candidateEducationId = computed(
        () => store.getters["candidateStore/getEducationIdOfCandidate"]
      ).value;

      Object.assign(formData, tempData);

      if (formState === "edit") {
        if (props.formName === "education") {
          modelRef.degreeId = tempData.degreeId;
          modelRef.instituteId = tempData.instituteId;
          modelRef.year = tempData.year;
        }
      }
    };

    const resetFormFields = () => {
      //Work Details
      modelRef.company = "";
      modelRef.designation = "";
      modelRef.workLevel = "";
      modelRef.reportingTo = "";
      modelRef.from = "";
      modelRef.to = "";
      modelRef.ctc = "";
    };

    const fetchVacancyListing = (value) => {
      let payload = {
        q: value,
        pageNo: currentPageNumber,
        mode: "AUTOCOMPLETE",
      };

      Services.getVacancyListing(payload)
        .then((response) => {
          const { data } = response;

          let tempData = [];

          console.log(" Vacancy response data:>> ", data);
          data[0].vacancies.map((x) => {
            tempData.push({
              value: x.vacancy.id,
              label: x.vacancy.title,
            });
            Object.assign(vacancy_name_options.value, tempData);
          });
        })

        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchProcedureStageData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["PROCEDURE"] };
      Services.getProcedureTypeDetail(payload)
        .then((response) => {
          const { data } = response;
          procedure_stage_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    function onProcedureStageChange() {
      store.dispatch(
        "candidateStore/candidateProfileSelectedProcedureStage",
        modelRef.procedureStageId
      );
    }

    function onSelectVacancyChange() {
      console.log('modelRef.vacancyId :>> ', modelRef.vacancyId);
      store.dispatch(
        "candidateStore/candidateProfileSelectedVacancy",
        modelRef.vacancyId
      );

        
    }

    function attachCandidateToVacancyInProfile() {
      context.emit("attachCandidateToVacancyInProfile", true);
      store.dispatch("candidateStore/attachCandidateToVacancyInProfile", true);
      // attachToVacancyVisible.value = false;/
      resetVacancyFields();
    }

    const resetVacancyFields = () => {
      modelRef.vacancyId = "";
      modelRef.procedureStageId = "";
    };

    function onHideAttachVacancyModal() {
      context.emit("onHideAttachVacancyModal", false);
      resetVacancyFields();
    }

    return {
      instituteOptions,
      eduDegreesOptions,
      onAddEducation,
      EducData,
      formState,
      candidateEducationId,
      candidateExperienceId,
      getEditEducationFormData,
      validate,
      validateInfos,
      resetFields,
      modelRef,
      formRef,
      onSubmit,
      destroymodal,
      value: ref([]),
      checked: ref(false),
      fileList: ref([]),
      date: ref(),
      meetingTeamfunct,
      meetingLevelfunct,
      meetinguserfunct,
      type: ref([]),
      meetingTeamsOptions,
      meetingOptions,
      meetingSub_teamsOptions,
      meetingcommentData: ref([]),
      meetinglevelOptions,
      meetingbyOptions,
      meeting_by: ref([]),
      teamIds: ref([]),
      getSubteam,
      subTeamIds: ref([]),
      level: ref([]),
      handleChange,
      props,
      options: [...Array(25)].map((_, i) => ({
        value: (i + 10).toString(36) + (i + 1),
      })),
      onAddWorkDetails,
      showWorkDetails,
      workData,
      apiData,
      onCancel,
      onHideAttachVacancyModal,
      attachCandidateToVacancyInProfile,
      fetchVacancyListing,
      fetchProcedureStageData,
      onProcedureStageChange,
      onSelectVacancyChange,
      vacancy_name_options,
      procedure_stage_tags_options,
    };
  },
});
</script>
<style lang="scss">
@import "@/features/candidate/styles/candidateProfile.scss";
</style>
